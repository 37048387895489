var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-icon',{staticStyle:{"border":"1px solid #5E6D7E","border-radius":"4px"}},[_vm._v(" handshake ")])],1),_c('v-col',{staticClass:"card-title-font"},[_vm._v("Comercial")])],1)],1),_c('CustomTabsComercialComponent',{attrs:{"selectedTab":_vm.selectedTab}}),_c('v-card',{staticClass:"pa-4 card-digital",class:_vm.isMobile ? 'mt-4' : 'ma-4'},[_c('v-row',{staticClass:"mb-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-title',{staticClass:"fira-semi-bold title-parcerias"},[_vm._v(" Caderno de matrículas ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.tipoFiltro),callback:function ($$v) {_vm.tipoFiltro=$$v},expression:"tipoFiltro"}},[_c('v-radio',{attrs:{"label":"Por assinatura do contrato","value":"C"}}),_c('v-radio',{attrs:{"label":"Por data pagamento","value":"P"}})],1)],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"align":"center"}},[_c('v-card-title',{staticClass:"fira-semi-bold title-parcerias"},[_vm._v(" Filtros ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-updated-component',{attrs:{"prop-title":_vm.tipoFiltro === 'C'
            ? 'Assinatura do contrato'
            : 'Por data pagamento'},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"rounded":"","dense":"","hide-details":"","outlined":"","label":"Buscar","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"buscar-button primary",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1)],1),_c('v-card-text',{staticClass:"table-ticket pa-4 articulat-medium"},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"card-consultor"},[_c('v-card-title',[_vm._v(" Totalizadores por consultor ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"table-ticket pa-4 articulat-medium",attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.headersTotalizadores,"items":_vm.totalPorConsultor,"dense":"","hide-default-footer":"","item-key":"name","show-select":""},scopedSlots:_vm._u([{key:"item.em_aberto",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totais[item.name][1])+" ")]}},{key:"item.pago",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totais[item.name][2])+" ")]}},{key:"item.cancelado",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totais[item.name][3])+" ")]}},{key:"item.renegociado",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totais[item.name][4])+" ")]}},{key:"item.protestado",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totais[item.name][5])+" ")]}},{key:"item.taxa_matricula_valor",fn:function(ref){
            var item = ref.item;
return [(_vm.totais[item.name].taxa_matricula_valor === 0)?_c('v-chip',{staticStyle:{"color":"#6400AC"},attrs:{"color":"#E6EDF4","rounded":""}},[_vm._v(" Isento ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatPriceValue( _vm.totais[item.name].taxa_matricula_valor ))+" ")])]}},{key:"item.ticket_medio",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPriceValue(_vm.totais[item.name].ticket_medio / item.value))+" ")]}},{key:"body.append",fn:function(ref){
            var headers = ref.headers;
return [_c('tr',[_c('td'),_c('td',[_c('b',[_vm._v("Totais")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.length))])]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',[_c('b',[_vm._v(_vm._s(_vm.formatPriceValue(_vm.totalTicketMedio / _vm.items.length)))])])]),_c('tr',[_c('td'),_c('td',[_c('b',[_vm._v("Total de alunos em turmas:")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.totalAlunosComTurma))])]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])]}}],null,true),model:{value:(_vm.selectedConsultor),callback:function ($$v) {_vm.selectedConsultor=$$v},expression:"selectedConsultor"}})],1)],1)],1)],1)],1)],1),_c('v-row',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',{staticStyle:{"overflow-x":"auto","max-width":"100%"}},[_c('v-data-table',{staticClass:"table-ticket-data pa-4 articulat-medium",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.search,"fixed-header":"","height":"calc(90vh - 200px)","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":true,"loading-text":"Carregando...","multi-sort":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.carregarMais()}}},[_vm._v(" carregar mais 100 ")])],1),_c('v-col',{staticClass:"pl-1"},[_vm._v(" Exibindo "+_vm._s(_vm.items.length)+" registro(s) ")])],1)]},proxy:true},{key:"item.aluno_nome",fn:function(ref){
            var item = ref.item;
return [_c('entidade-link-component',{attrs:{"propEntidadeId":item.aluno_id,"propEntidadeNome":item.aluno_nome,"propEntidadeTipo":"aluno"}})]}},{key:"item.aluno_contatos",fn:function(ref){
            var item = ref.item;
return _vm._l((item.aluno_contatos),function(contato,idx){return _c('div',{key:idx},[(contato.observation)?_c('span',[_vm._v(_vm._s(contato.observation)+" -")]):_vm._e(),_vm._v(" "+_vm._s(contato.phone)),_c('br')])})}},{key:"item.como_conheceu_knn",fn:function(ref){
            var item = ref.item;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(item.como_conheceu_knn)+" ")])]}},{key:"item.curso_desconto_percentual",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v("Curso: "+_vm._s(item.curso_desconto_percentual)+"%")]),_c('br'),(!item.pre_invoice_id)?_c('span',[_vm._v("Material: "+_vm._s(item.material_didatico_desconto_percentual)+"%")]):_vm._e(),(item.pre_invoice_id)?_c('livro-solicitado-direto-franchise-button',{attrs:{"propPreInvoiceId":item.pre_invoice_id,"propPreInvoiceStatusId":item.pre_invoice_status_id}}):_vm._e()]}},{key:"item.turma_id",fn:function(ref){
            var item = ref.item;
return [(item.turma_codigo_referencia)?_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.turma_codigo_referencia)),"propObjectId":item.turma_id,"propToolName":"Turma"}}):_c('span',[_vm._v("N/D")])]}},{key:"item.vigencia_inicio",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vigencia_inicio))+" ")]}},{key:"item.indicou_aluno_id",fn:function(ref){
            var item = ref.item;
return [(item.indicou_aluno_id)?_c('entidade-link-component',{attrs:{"propEntidadeNome":("" + (item.indicou_aluno_nome)),"propEntidadeId":item.indicou_aluno_id,"propToolName":"aluno"}}):_c('span',[_vm._v("N/D")])]}},{key:"item.curso_primeira_parcela_status_id",fn:function(ref){
            var item = ref.item;
return [(item.curso_primeira_parcela_status_id >= 0)?_c('div',[_c('v-chip',{attrs:{"color":_vm.getStatusFinanceiroParcelaStatus(
                  item.curso_primeira_parcela_status_id
                ).color}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus( item.curso_primeira_parcela_status_id ).name)+" ")]),_c('small',[_vm._v(" R$"+_vm._s(_vm._f("formatPriceValue")(item.curso_primeira_parcela_valor))+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.curso_primeira_parcela_pagamento))+" ")])],1):_c('v-chip',{attrs:{"label":"","color":"error","rounded":""}},[_vm._v(" Não possui ")])]}},{key:"item.material_didatico_primeira_parcela_status_id",fn:function(ref){
            var item = ref.item;
return [(item.material_didatico_primeira_parcela_status_id >= 0)?_c('div',[_c('v-chip',{attrs:{"color":_vm.getStatusFinanceiroParcelaStatus(
                  item.material_didatico_primeira_parcela_status_id
                ).color}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus( item.material_didatico_primeira_parcela_status_id ).name)+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.material_didatico_primeira_parcela_pagamento))+" ")])],1):_c('v-chip',{staticStyle:{"border-radius":"16px !important"},attrs:{"label":"","color":"error"}},[_vm._v(" Não possui ")])]}},{key:"item.material_didatico_forma_pagamento",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.material_didatico_forma_pagamento)+" ")]}},{key:"item.local_aula_id",fn:function(ref){
                var item = ref.item;
return [_c('AulaLocalShowButton',{staticClass:"ml-2",attrs:{"propAulaLocal":item.local_aula_id,"propEscolaId":_vm.userProfile.company.escola_id}})]}},{key:"item.taxa_matricula_valor",fn:function(ref){
                var item = ref.item;
return [(item.taxa_matricula_valor === 0)?_c('v-chip',{staticStyle:{"color":"#6400AC"},attrs:{"color":"#E6EDF4","rounded":""}},[_vm._v(" Isento ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatPriceValue(item.taxa_matricula_valor))+" ")])]}},{key:"item.curso_valor_integral",fn:function(ref){
                var item = ref.item;
return [_vm._v(" R$"+_vm._s(_vm._f("formatPriceValue")(item.curso_valor_integral))+" ")]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }